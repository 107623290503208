import { calcularTotalOpcionais } from '@/helpers/calculateOptions/calculateOptionsHelper';
import CommonMask from '@/helpers/masks';
import useTranslation from '@/hooks/cardapioUseTranslation';
import { CartType } from '@/models/cartModel';
import { ValidCouponResponse } from '@/models/delivery/validCoupon.response';
import { useAppSelector } from '@/redux/hooks';
import { setSelectedItens } from '@/redux/reducers/order';
import { Button } from '@mui/material';
import React from 'react';

type FooterType = {
	btnTxt?: string;
	disabled?: boolean;
	onClick?(): void;
	tot: number;
	enableBtn?: boolean;
	qtd: number;
	hiddenInfos?: boolean;
	shipping?: boolean;
	shippingTax?: number;
	coupon?: string;
	couponResponse?: ValidCouponResponse | null;
	openCoupon?: boolean;
	cart?: CartType;
	seo?: any;
};

export default function FooterBottomSheet({ btnTxt, disabled, onClick, tot, qtd, enableBtn = true, hiddenInfos = false, shipping = false, shippingTax = 0, coupon, couponResponse, openCoupon, cart }: FooterType) {
	const { client } = useAppSelector(state => state.auth);
	const { selectedItens } = useAppSelector(state => state.order);
	const { returnTranslation } = useTranslation();

	function calcTotPerItem(item: CartType['items'][0]) {
		let newTotOptions = 0;
		if (item.options) {
			newTotOptions = calcularTotalOpcionais(item.options);
		}

		const newTot = (newTotOptions + item.unityValue) * item.qtd;	
		return newTot;
	}

	function renderTot() {
		if (couponResponse) {
			if (couponResponse.conteudo.entrega_gratis) {
				return CommonMask.currency(tot.toFixed(2).toString());
			} else {
				if (couponResponse.conteudo.desconto) {
					if (couponResponse.conteudo.tipo_desconto === 'P') {
						if (couponResponse.conteudo.unid_med && couponResponse.conteudo.unid_med.length) {
							let newTot = 0;

							cart?.items.forEach(product => {
								if (couponResponse.conteudo.unid_med.includes(product.idUnity)) {
									const calc = (calcTotPerItem(product) / 100) * couponResponse.conteudo.desconto;
									newTot += calc;
								}
							});

							return CommonMask.currency((tot + shippingTax - newTot).toFixed(2).toString());
						} else {
							const newTot = shippingTax + tot;
							const calc = (tot / 100) * couponResponse.conteudo.desconto;
							return CommonMask.currency((newTot - calc).toFixed(2).toString());
						}
					} else {
						if (couponResponse.conteudo.unid_med && couponResponse.conteudo.unid_med.length) {
							let newTot = 0;

							cart?.items.forEach(product => {
								if (couponResponse.conteudo.unid_med.includes(product.idUnity)) {
									const calc = calcTotPerItem(product) - couponResponse.conteudo.desconto;
									newTot += calc;
								} else {
									newTot += calcTotPerItem(product);
								}
							});

							return CommonMask.currency((shippingTax - newTot).toFixed(2).toString());
						} else {
							return CommonMask.currency(shippingTax + tot - couponResponse.conteudo.desconto < 0 ? '0,00' : (shippingTax + tot - couponResponse.conteudo.desconto).toFixed(2).toString());
						}
					}
				}
				return CommonMask.currency((shippingTax + tot).toFixed(2).toString());
			}
		} else {
			return CommonMask.currency((shippingTax + tot).toFixed(2).toString());
		}
	}

	function renderCoupon() {
		if (couponResponse && couponResponse.sucesso) {
			if (couponResponse.conteudo.entrega_gratis) {
				return <></>;
			} else {
				if (couponResponse.conteudo.tipo_desconto === 'P') {
					return `: - ${couponResponse.conteudo.desconto}%`;
				} else {
					return `: - R$ ${CommonMask.currency(couponResponse.conteudo.desconto.toFixed(2).toString())}`;
				}
			}
		} else {
			return <></>;
		}
	}

	return (
		<footer id="footerBottomSheet">
			{!hiddenInfos && (
				<>
					{shipping ? (
						<>
							<div className="row">
								<p className="footerSubtotTxt">Subtotal</p>
								<p className="footerSubtotTxt">
									<span>R$</span> {CommonMask.currency(tot.toFixed(2).toString())}
								</p>
							</div>
							<div className="row">
								<p className="footerSubtotTxt">{returnTranslation('deliveryFee')} </p>
								<p className="footerSubtotTxt">
									{couponResponse?.conteudo.entrega_gratis ? (
										<>{returnTranslation('freeShipping')} </>
									) : (
										<>
											<span>R$</span> {CommonMask.currency(shippingTax.toFixed(2).toString())}
										</>
									)}
								</p>
							</div>
							{coupon && couponResponse?.sucesso && !openCoupon && (
								<div className="row">
									<p className="footerSubtotTxt">{returnTranslation('couponApplied')} </p>
									<p className="footerSubtotTxt">
										{coupon}
										{renderCoupon()}
									</p>
								</div>
							)}

							<div className="row mt-2">
								<p className="footerTotTxt">Total</p>
								<p className="footerValue">
									<span>R$</span>
									{renderTot()}
								</p>
							</div>
						</>
					) : (
						<div className="footerContent">
							{!client?.config_cliente?.oculta_total && (
								<>
									<div>
										<p className="footerTotTxt">Total</p>
										<p className="footerQtdTxt">
											{returnTranslation('resultItems')}
											{client?.config_cliente?.agrupa_itens_mesa ? (selectedItens.length > 0 ? `${selectedItens.length} ${returnTranslation('items')}` : `${qtd > 0 ? qtd : '0'} ${returnTranslation('items')}`) : `${qtd} ${returnTranslation('items')}`}
										</p>
									</div>
									<p className="footerValue">
										<span>R$</span>
										{client?.config_cliente?.agrupa_itens_mesa
											? selectedItens.length > 0
												? CommonMask.currency(
														selectedItens
															.reduce((acc, item) => acc + (item.total || 0), 0)
															.toFixed(2)
															.toString(),
												  )
												: setSelectedItens.length < 0
												? '0,00'
												: CommonMask.currency(tot.toFixed(2).toString())
											: CommonMask.currency(tot.toFixed(2).toString())}
									</p>
								</>
							)}
						</div>
					)}
				</>
			)}
			{enableBtn && (
				<Button id="ad_delivery" variant="contained" className="btnDefault" onClick={onClick} disabled={disabled}>
					{btnTxt}
				</Button>
			)}
		</footer>
	);
}
