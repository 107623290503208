import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DoLoginFidelityCardapio } from '@/services/cardapioFidelity/auth';
import { LoginFidelityResponse } from '@/models/loginFidelity.response';
import UserStorage from '@/storages/user-storage';

const initialState = {
	loading: false,
	loadingForm: false,
	responseLogin: <LoginFidelityResponse | null>null,
	requestError: <null | string>null,
	fidelityModalpage: 0,
	openFidelidade:false,
};

const authFidelityCardapio = createSlice({
	name: 'cardapioAuthFidelity',
	initialState,
	reducers: {
		reset: () => initialState,
		setOpenFidelidade: (state, action: PayloadAction<boolean>) => {
			state.openFidelidade = action.payload; 
		},
		setFidelityModalPage: (state, action) => {
			state.fidelityModalpage = action.payload;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(DoLoginFidelityCardapio.pending, state => {
				state.requestError = null;
				state.responseLogin = null;
				state.loadingForm = true;
			})
			.addCase(DoLoginFidelityCardapio.fulfilled, (state, { payload }) => {
				state.loadingForm = true;
				if (payload) {
					if (payload.retorno) {
						UserStorage.SetFidelityLogin(payload.usuario_id);
						state.fidelityModalpage = 1
					} else {
						state.requestError = payload.mensagem;
						state.loadingForm = false;
					}
				}
			});
	},
});

export const { reset , setOpenFidelidade, setFidelityModalPage} = authFidelityCardapio.actions;
export default authFidelityCardapio.reducer;
