const CommonClears = {
	clearCPF(cpf: string) {
		return cpf.replaceAll('.', '').replaceAll('-', '').replaceAll(/[^0-9]/g, '');
	},
	clearCurrency(value: string, returnNumber?: boolean) {
		const toClear = +value.replace(/\D/g, '');
		if (returnNumber) {
			if (value.includes(',')) {
				return toClear / 100;
			} else {
				return toClear;
			}
		} else {
			return toClear.toString();
		}
	},
	clearPhone(phone: string) {
		return phone.replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '').replaceAll('-', '').replaceAll(/\D/g, '');
	},
	clearCEP(cep: string) {
		return cep.replaceAll('-', '').replaceAll(/[^0-9]/g, '');
	},
	clearCard(card: string) {
		return card.replaceAll(/ /g, '');
	},
};

export default CommonClears;
