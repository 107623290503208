import { createSlice } from '@reduxjs/toolkit';
import UserStorage from '@/storages/user-storage';
import { RegisterFidelityResponse } from '@/models/registerFidelity.response';
import { CardapioDoRegisterFidelity } from '@/services/cardapioFidelity/register';

const initialState = {
	loading: false,
	loadingForm: false,
	responseLogin: <RegisterFidelityResponse | null>null,
	requestError: <null | string>null,
	fidelityModalpage: 0,
};

const cardapioRegisterFidelitySlice = createSlice({
	name: 'cardapioRegisterFidelity',
	initialState,
	reducers: {
		reset: () => initialState,
	},
	extraReducers: builder => {
		builder
			.addCase(CardapioDoRegisterFidelity.pending, state => {
				state.requestError = null;
				state.responseLogin = null;
				state.loadingForm = true;
			})
			.addCase(CardapioDoRegisterFidelity.fulfilled, (state, { payload }) => {
				state.loadingForm = true;
				if (payload) {
					if (payload.retorno) {
						UserStorage.SetFidelityLogin(payload.usuario_id);
					} else {
						state.requestError = payload.mensagem;
						state.loadingForm = false;
					}
				}
			});
	},
});

export const { reset } = cardapioRegisterFidelitySlice.actions;
export default cardapioRegisterFidelitySlice.reducer;
