import CommonClears from '@/helpers/clears/common.clear';
import CommonMask from '@/helpers/masks';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { DoLogin, GetCustomerData, GetLogin, StatusSession } from '@/services/auth';
import { Button, CircularProgress, Modal, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useMain } from '@/contexts/main';
import CommonValidators from '@/helpers/validators/common.validators';
import ArrowIcon from '@/assets/svg/Arrow';
import ModalError from '../ModalError';
import { useRouter } from 'next/router';
import useTranslation from '@/hooks/cardapioUseTranslation';

Array.from({ length: 40 }, () => Math.floor(Math.random() * 40));

const months = Array.from({ length: 12 }, (v, i) => (i < 9 ? '0' + ++i : (++i).toString()));
const days = Array.from({ length: 31 }, (v, i) => (i < 9 ? '0' + ++i : (++i).toString()));
const years = Array.from({ length: 150 }, (v, i) => (new Date().getFullYear() - i).toString());

export default function ModalLogin() {
	const router = useRouter();
	const { loginFields, requestError, client, responseLogin, loadingForm, customerData, statusResponse } = useAppSelector(state => state.auth);
	const dispatch = useAppDispatch();
	const [form, setForm] = useState<any>({});
	const [showSnack, setShowSnack] = useState(false);
	const [open, setOpen] = useState(false);
	const [snackMsg, setSnackMsg] = useState('');
	const { storageLogin, qrcode } = useMain();
	const { returnTranslation } = useTranslation();
	const [date, setDate] = useState({
		day: '',
		month: '',
		year: '',
	});

	useEffect(() => {
		if (client && qrcode) {
			(async () => {
				if (!client.config_cliente.somente_cardapio) {
					await dispatch(GetLogin({ qrcode }));
				}
			})();
		}
	}, [client, qrcode]);

	useEffect(() => {
		if (client && storageLogin) {
			(async () => {
				if (!client.config_cliente.somente_cardapio) {
					await dispatch(StatusSession({ comanda_id: storageLogin.comanda_id }));
				}
			})();
		}
	}, [client, storageLogin]);

	useEffect(() => {
		if (loginFields && !loginFields.config_qrcode.pre_pago && ((statusResponse && !statusResponse.sucesso) || !storageLogin)) {
			const newForm: any = {};
			loginFields?.campos_login?.forEach(field => {
				newForm[field.name] = '';
			});
			setForm(newForm);
			setOpen(true);
		}
	}, [loginFields, statusResponse]);

	useEffect(() => {
		if (statusResponse && !statusResponse.sucesso) {
			localStorage.clear();
			sessionStorage.clear();
		}
	}, [statusResponse]);

	useEffect(() => {
		if (responseLogin) {
			if (responseLogin.retorno) {
				setOpen(false);
			} else if (responseLogin.mensagem) {
				setSnackMsg(responseLogin.mensagem);
			}
		}
	}, [responseLogin]);

	useEffect(() => {
		if (requestError) {
			setSnackMsg(requestError);
			setShowSnack(true);
		}
	}, [requestError]);

	function sanitizeInput(value: string) {
		let newValue = '';
		const regEx = /[^\d.-]/g;
		newValue = value.replaceAll(regEx, '');
		return newValue;
	}

	function handleClose() {
		setShowSnack(false);
	}

	function handleChange(value: string | number, fieldName: string) {
		setForm({ ...form, [fieldName]: value });
	}

	useEffect(() => {
		if (date.day && date.month && date.year) {
			setForm({
				...form,
				dt_nascimento_comanda: `${date.day}/${date.month}/${date.year}`,
			});
		} else {
			setForm({
				...form,
				dt_nascimento_comanda: '',
			});
		}
	}, [date]);

	async function sendForm() {
		const missingFields = loginFields?.campos_login?.filter(field => !form[field.name]?.trim());
		if (missingFields?.length) {
			setSnackMsg(returnTranslation('requiredFields'));
			setShowSnack(true);
			return;
		}

		const phoneField = loginFields?.campos_login?.find(field => field.tipo === 'FONE');
		if (phoneField && (!form[phoneField.name] || form[phoneField.name].length < 9)) {
			setSnackMsg(returnTranslation('phoneNumber'));
			setShowSnack(true);
			return;
		}

		await dispatch(
			DoLogin({
				qrcode_id: loginFields?.config_qrcode.id || 0,
				...form,
			}),
		);
	}

	async function getCustomerData(cpf: string) {
		if (cpf) {
			await dispatch(GetCustomerData({ busca: cpf }));
		}
	}

	useEffect(() => {
		if (form.codigo_unico) {
			const cpfValidator = CommonValidators.isCPFValid(form.codigo_unico);
			if (cpfValidator.status) {
				getCustomerData(form.codigo_unico);
			} else {
				const phoneValidator = CommonValidators.isCellphoneValid(form.codigo_unico);
				if (phoneValidator.status) {
					getCustomerData(form.codigo_unico);
				}
			}
		}
	}, [form.codigo_unico]);

	useEffect(() => {
		if (customerData?.conteudo) {
			let fillForm: any = {};

			if (form.fone_comanda === '') {
				fillForm = {
					...fillForm,
					fone_comanda: customerData.conteudo.fone || '',
				};
			}
			if (form.nome_comanda === '') {
				fillForm = {
					...fillForm,
					nome_comanda: customerData.conteudo.nome || '',
				};
			}
			if (form.dt_nascimento_comanda === '') {
				fillForm = {
					...fillForm,
					dt_nascimento_comanda: customerData.conteudo.dt_nascimento || '',
				};
				if (customerData.conteudo.dt_nascimento) {
					let newArrDate = customerData.conteudo.dt_nascimento.split('/');

					setDate({
						day: newArrDate[0],
						month: newArrDate[1],
						year: newArrDate[2],
					});
				}
			}

			setForm({
				...form,
				...fillForm,
			});
		}
	}, [customerData]);

	return (
		<>
			<Modal open={open}>
				<div id="modalLogin">
					<div className="content">
						{loadingForm ? (
							<div className="loadingDefault" style={{ minHeight: 0 }}>
								<CircularProgress className="loading" />
								<p>Efetuando login, aguarde por favor...</p>
							</div>
						) : (
							<>
								<p className="title">Comanda</p>
								{loginFields?.campos_login?.map(field => {
									if (field.tipo === 'SELECT') {
										return (
											<div className="blockSelect" key={field.name}>
												<div className="selectIcon">
													<ArrowIcon />
												</div>
												<select className="select" value={form[field.name]} onChange={event => handleChange(event.target.value, field.name)} disabled={loadingForm}>
													<option hidden selected value="">
														{field.label}
													</option>
													{field.nr_mesas?.map((item, index) => (
														<option value={item} key={index}>
															{item}
														</option>
													))}
												</select>
											</div>
										);
									}

									if (field.tipo === 'DATA') {
										return (
											<div className="blockDate" key={field.name}>
												<p className="dateLabel">{field.label}</p>
												<div className="rowDate">
													<div className="blockSelect">
														<div className="selectIcon">
															<ArrowIcon />
														</div>
														<select className="select" value={date.day} onChange={event => setDate({ ...date, day: event.target.value })} disabled={loadingForm}>
															<option hidden selected value="">
																DD
															</option>
															{days.map((item, index) => (
																<option value={item} key={index}>
																	{item}
																</option>
															))}
														</select>
													</div>
													<div className="blockSelect">
														<div className="selectIcon">
															<ArrowIcon />
														</div>
														<select className="select" value={date.month} onChange={event => setDate({ ...date, month: event.target.value })} disabled={loadingForm}>
															<option hidden selected value="">
																MM
															</option>
															{months.map((item, index) => (
																<option value={item} key={index}>
																	{item}
																</option>
															))}
														</select>
													</div>
													<div className="blockSelect">
														<div className="selectIcon">
															<ArrowIcon />
														</div>
														<select className="select" value={date.year} onChange={event => setDate({ ...date, year: event.target.value })} disabled={loadingForm}>
															<option hidden selected value="">
																AAAA
															</option>
															{years.map((item, index) => (
																<option value={item} key={index}>
																	{item}
																</option>
															))}
														</select>
													</div>
												</div>
											</div>
										);
									}

									return (
										<TextField
											key={field.name}
											value={field.tipo === 'CPF' ? CommonMask.cpf(form[field.name] || '') : field.tipo === 'FONE' ? CommonMask.phone(form[field.name] || '') : form[field.name]}
											name={field.name}
											required
											fullWidth
											className="field"
											label={field.label}
											variant="outlined"
											disabled={loadingForm}
											onChange={event => {
												if (field.tipo === 'CPF') {
													const realValue = sanitizeInput(event.target.value);
													if (event.target.value.length <= 14) {
														handleChange(realValue, field.name);
													}
													if (!realValue) {
														handleChange(' ', field.name);
													}
												} else if (field.tipo === 'INT' || field.tipo === 'NUMERICO' || field.tipo === 'FONE') {
													const realValue = sanitizeInput(event.target.value);
													handleChange(realValue, field.name);
												} else if (field.tipo === 'DATA') {
													if (event.target.value.length <= 10) {
														const value = CommonClears.clearPhone(event.target.value);
														handleChange(value, field.name);
													}
												} else {
													const value = event.target.value;
													handleChange(value, field.name);
												}
											}}
										/>
									);
								})}

								<Button variant="contained" disabled={loadingForm} className="btn" onClick={sendForm}>
									{loadingForm ? returnTranslation('Accessing') : returnTranslation('Access')}
								</Button>
								{client?.config_cliente.botao_cardapio_comanda && (
									<Typography
										sx={{ textDecoration: 'underline', display: 'flex', justifyContent: 'center', paddingTop: '2vh', color: '#' + client?.config_cliente.cor }}
										onClick={() => {
											sessionStorage.clear();
											setOpen(false);
											router.reload();
										}}>
										{'Ver Cardápio'}
									</Typography>
								)}
							</>
						)}
					</div>
					<ModalError open={showSnack} errorMsg={snackMsg} setOpen={handleClose} />
				</div>
			</Modal>
		</>
	);
}
