import { createAsyncThunk } from '@reduxjs/toolkit';
import { LocationFidelityResponse } from '@/models/locationFidelity.response';
import api from './api';

export const CardapioLocationFidelity = createAsyncThunk(
  'auth/CardapioLocationFidelity',
  async (userId: number) => {
    const response = await api
      .get('/locais-retirada')
      .then((r): LocationFidelityResponse => {
        return r.data;
      })
      .catch((error) => {
        return error.response.data;
      });
    return response;
  }
);
