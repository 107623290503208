import { createSlice } from '@reduxjs/toolkit';
import { ListOrder, PostOrder } from '@/services/order';
import { MakeOrderResponse } from '@/models/makeOrder.response';
import { ListOrderResponse, ListOrderResponseProduct } from '@/models/listOrder.response';

const initialState = {
	orderResponse: <MakeOrderResponse | null>null,
	list: <ListOrderResponse | null>null,
	loading: false,
	selectedItens: <ListOrderResponseProduct[]>[],
	requestError: <null | string>null,
};

const orderSlice = createSlice({
	name: 'order',
	initialState,
	reducers: {
		resetOrder: () => initialState,
		resetListOrder: state => {
			state.list = null;
		},
		resetOrderResponse: state => {
			state.orderResponse = null;
			state.requestError = null;
		},
		// Novo reducer para atualizar o array de selectedItens
		setSelectedItens: (state, action) => {
			state.selectedItens = action.payload;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(PostOrder.pending, state => {
				state.requestError = null;
				state.orderResponse = null;
				state.loading = true;
			})
			.addCase(PostOrder.fulfilled, (state, { payload }) => {
				state.loading = false;
				if (payload) {
					if (payload.retorno) {
						state.orderResponse = payload;
					} else {
						if (String(payload.status).startsWith('4')) {
							state.requestError = 'A página não existe.';
						} else if (String(payload.status).startsWith('5')) {
							state.requestError = 'Erro do servidor.';
						} else {
							state.requestError = payload.mensagem;
						}
					}
				}
			})
			.addCase(ListOrder.pending, state => {
				state.requestError = null;
				state.loading = true;
			})
			.addCase(ListOrder.fulfilled, (state, { payload }) => {
				state.loading = false;
				if (payload) {
					if (payload.retorno) {
						state.list = payload;
					} else {
						console.log(payload);
						if (String(payload.status).startsWith('4')) {
							state.requestError = 'A página não existe.';
						} else if (String(payload.status).startsWith('5')) {
							state.requestError = 'Erro do servidor.';
						} else {
							state.requestError = payload.mensagem;
						}
					}
				}
			});
	},
});

export const { resetOrder, resetListOrder, resetOrderResponse, setSelectedItens } = orderSlice.actions;
export default orderSlice.reducer;
