import { createSlice } from '@reduxjs/toolkit';
import { LocationFidelityResponse } from '@/models/locationFidelity.response';
import { CardapioLocationFidelity } from '@/services/cardapioFidelity/location';


const initialState = {
  loading: true,
  locationResponse: <LocationFidelityResponse | null>null,
  requestError: <null | string>null,
};

const cardapioLocationFidelitySlice = createSlice({
  name: 'cardapiolocationFidelity',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
    .addCase(CardapioLocationFidelity.pending, (state) => {
      state.requestError = null;
      state.locationResponse = null;
    })
    .addCase(CardapioLocationFidelity.fulfilled, (state, { payload }) => {
      if (payload) {
        if (payload.retorno) {
         state.locationResponse = payload
         state.loading = false
        } else {
          state.requestError = payload.mensagem;
        }
      }
    });
  },
});

export const { reset } = cardapioLocationFidelitySlice.actions;
export default cardapioLocationFidelitySlice.reducer;
