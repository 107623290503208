import ClockIcon from '@/assets/svg/Clock';
import PhoneIcon from '@/assets/svg/Phone';
import PinIcon from '@/assets/svg/Pin';
import { usePages } from '@/contexts/pages';
import { useAppSelector } from '@/redux/hooks';
import { Box, Button, Dialog, DialogContent, Slide, Typography } from '@mui/material';
import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { TransitionProps } from '@mui/material/transitions';
import { WorkingHoursModel } from '@/models/workingHoursModel';
import { p500 } from '@/styles/typography/fonts';
import { GiRoundStar } from 'react-icons/gi';
import RatingScreenCardapio from '@/layout/topAppBar/components/ratingScreenCardapio';
import useTranslation from '@/hooks/cardapioUseTranslation';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function InformationsPage() {
	const { client } = useAppSelector(state => state.auth);
	const { openInformation, setOpenInformation } = usePages();
	const [ratingScreenOpen, setRatingScreenOpen] = useState(false);
	const { changeApiLanguage, returnTranslation } = useTranslation();

	function onDismiss() {
		setOpenInformation(false);
	}

	return (
		<Dialog fullScreen open={openInformation} onClose={onDismiss} TransitionComponent={Transition} id="informations" scroll="paper" className="customDialog" disableScrollLock>
			<Button variant="contained" className="btnDown" onClick={onDismiss}>
				<KeyboardArrowDownIcon />
			</Button>
			<DialogContent>
				<section
					className="banner"
					style={{
						backgroundImage: `url(${client?.config_cliente.img_fundo})`,
					}}>
					<h1 className="bannerName">{client?.config_cliente.titulo_mobile}</h1>
				</section>
				<section className="content">
					<div className="card">
						<ul className="list">
							{client?.config_cliente.horario_funcionamento && (
								<li className="listItem">
									<div className="listIcon">
										<ClockIcon />
									</div>
									<div className="listContent">
										<p className="listTitle">{returnTranslation('businessHours')} </p>
										{client?.config_cliente.horario_funcionamento?.map(day => {
											const dayKey = Object.keys(day);
											const realKey = dayKey[0] as keyof WorkingHoursModel;
											return (
												<p key={realKey} className="listDesc">
													{realKey + ': ' + day[realKey]}
												</p>
											);
										})}
									</div>
								</li>
							)}

							{client?.config_cliente.endereco && (
								<li className="listItem">
									<div className="listIcon">
										<PinIcon />
									</div>
									<div className="listContent">
										<p className="listTitle">{returnTranslation('address')}</p>
										<p className="listDesc">{client?.config_cliente.endereco}</p>
									</div>
								</li>
							)}

							{client?.config_cliente && client?.config_cliente?.fone?.length > 1 && (
								<li className="listItem">
									<div className="listIcon">
										<PhoneIcon />
									</div>
									<div className="listContent">
										<p className="listTitle">{returnTranslation('phone')}</p>
										<p className="listDesc">{client?.config_cliente.fone}</p>
									</div>
								</li>
							)}

							<li>
								{client?.config_cliente?.usa_avaliacao && (
									<Box className="boxAvalie" onClick={() => setRatingScreenOpen(true)}>
										<GiRoundStar style={{ fontSize: '0.95rem' }} />
										<Typography sx={{ fontFamily: p500, fontSize: '0.90rem', marginLeft: '1vw' }}>{returnTranslation('review')}</Typography>
									</Box>
								)}
							</li>
						</ul>
					</div>
					<RatingScreenCardapio open={ratingScreenOpen} setOpen={setRatingScreenOpen} isPage={true} />
				</section>
			</DialogContent>
		</Dialog>
	);
}
