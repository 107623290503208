import { createSlice } from '@reduxjs/toolkit';
import { FuncionariosFidelityResponse } from '@/models/registerFidelity.response';
import { CardapioColaboratorsFidelity } from '@/services/cardapioFidelity/register';


const initialState = {
  loading: false,
  loadingForm: false,
  data: <FuncionariosFidelityResponse | null>null,
  requestError: <null | string>null,
};

const cardapioColaboratorFidelitySlice = createSlice({
  name: 'cardapioColaboratorFidelity',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
    .addCase(CardapioColaboratorsFidelity.pending, (state) => {
      state.requestError = null;
      state.data = null;
      state.loadingForm = true;
    })
    .addCase(CardapioColaboratorsFidelity.fulfilled, (state, { payload }) => {
      
      if (payload) {
        if (payload.retorno) {
          state.data = payload;
        } else {
          state.requestError = payload.mensagem;
          state.loadingForm = false;
        }
      }
    });
  },
});

export const { reset } = cardapioColaboratorFidelitySlice.actions;
export default cardapioColaboratorFidelitySlice.reducer;
