import { DoLogin, GetClient, GetCustomerData, GetLogin, StatusSession, SetLoggedTicket } from '@/services/auth';
import { SearchClienteResponse } from '@/models/client.response';
import { createSlice } from '@reduxjs/toolkit';
import { LoginQrcodeResponse } from '@/models/loginQrcode.response';
import { LoginResponse } from '@/models/login.response';
import UserStorage from '@/storages/user-storage';
import { CustomerDataResponse } from '@/models/customerData.response';
import { SessionStatusResponse } from '@/models/sessionStatus.response';

const initialState = {
	client: <SearchClienteResponse | null>null,
	loggedTicket: <boolean | null>null,
	customerData: <CustomerDataResponse | null>null,
	loginFields: <LoginQrcodeResponse | null>null,
	responseLogin: <LoginResponse | null>null,
	statusResponse: <SessionStatusResponse | null>null,
	loading: false,
	loadingForm: false,
	requestError: <null | string>null,
	// linguagem
	language: 'PT' as 'PT' | 'EN' | 'ES',
	iconRating: true,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		reset: () => initialState,
		changeAutoLanguage: (state, action) => {
			state.language = action.payload;
		},
		changeHideIconRating: (state) => {
			state.iconRating = false;
		  },
		 
	},
	extraReducers: (builder) => {
		builder
			.addCase(SetLoggedTicket.pending, (state) => {
				state.requestError = null;
				state.loggedTicket = null;
				state.loading = true;
			})
			.addCase(SetLoggedTicket.fulfilled, (state, { payload }) => {
				state.loading = false;
				if (payload) {
					if (payload.loggedTicket) {
						state.loggedTicket = payload.loggedTicket;
					} 
				}
			})
			.addCase(GetClient.pending, (state) => {
				state.requestError = null;
				state.client = null;
				state.loading = true;
			})
			.addCase(GetClient.fulfilled, (state, { payload }) => {
				state.loading = false;
				if (payload) {
					if (payload.retorno) {
						state.client = payload;
					} else {
						state.requestError = payload.mensagem;
					}
				}
			})
			.addCase(GetLogin.pending, (state) => {
				state.requestError = null;
				state.loginFields = null;
				state.loading = true;
			})
			.addCase(GetLogin.fulfilled, (state, { payload }) => {
				state.loading = false;
				if (payload) {
					if (payload.retorno) {
						state.loginFields = payload;
					} else {
						state.requestError = payload.mensagem;
					}
				}
			})
			.addCase(DoLogin.pending, (state) => {
				state.requestError = null;
				state.responseLogin = null;
				state.loadingForm = true;
			})
			.addCase(DoLogin.fulfilled, (state, { payload }) => {
				state.loadingForm = false;
				if (payload) {
					if (payload.retorno) {
						UserStorage.SetLogin(payload);
						state.responseLogin = payload;
					} else {
						state.requestError = payload.mensagem;
					}
				}
			})
			.addCase(GetCustomerData.pending, (state) => {
				state.requestError = null;
				state.customerData = null;
				state.loading = true;
			})
			.addCase(GetCustomerData.fulfilled, (state, { payload }) => {
				state.loading = false;
				if (payload) {
					if (payload.retorno) {
						state.customerData = payload;
					} else {
						state.requestError = payload.mensagem;
					}
				}
			})
			.addCase(StatusSession.pending, (state) => {
				state.requestError = null;
				state.statusResponse = null;
				state.loading = true;
			})
			.addCase(StatusSession.fulfilled, (state, { payload }) => {
				state.loading = false;
				if (payload) {
					state.statusResponse = payload;
				}
			});
			
	},
});

export const { reset, changeAutoLanguage, changeHideIconRating } = authSlice.actions;
export default authSlice.reducer;
