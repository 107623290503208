import { createSlice } from '@reduxjs/toolkit';
import { PunctuationFidelityResponse } from '@/models/punctuationFidelity.response';
import { CardapioPunctuationFidelity } from '@/services/cardapioFidelity/punctuation';

const initialState = {
	loading: true,
	response: <PunctuationFidelityResponse | null>null,
	requestError: <null | string>null,
};

const cardapioPunctuationFidelitySlice = createSlice({
	name: 'cardapioPunctuationFidelity',
	initialState,
	reducers: {
		reset: () => initialState,
		startFidelityLoading: (state, action) => {
			state.loading = true;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(CardapioPunctuationFidelity.pending, state => {
				state.requestError = null;
				state.response = null;
			})
			.addCase(CardapioPunctuationFidelity.fulfilled, (state, { payload }) => {
				if (payload) {
					if (payload.retorno) {
						state.response = payload.conteudo;
						state.loading = false;
					} else {
						state.requestError = payload.mensagem;
					}
				}
			});
	},
});

export const { reset, startFidelityLoading } = cardapioPunctuationFidelitySlice.actions;
export default cardapioPunctuationFidelitySlice.reducer;
