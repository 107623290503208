export type Opcional = {
    catId: number;
    name: string;
    qtd: number;
    value: number;
    maiorPreco: boolean;
};

export const calcularTotalOpcionais = (opcionais: Opcional[]): number => {
    // Agrupa os itens por catId
    const grupos = opcionais.reduce<Record<number, Opcional[]>>((acc, item) => {
        if (!acc[item.catId]) acc[item.catId] = [];
        acc[item.catId].push(item);
        return acc;
    }, {});

    // Calcula o total respeitando as regras
    let total = 0;
    for (const catId in grupos) {
        const grupo = grupos[catId];
        const maiorPreco = grupo.some(item => item.maiorPreco);

        if (maiorPreco) {
            // Pega o maior valor multiplicado pela quantidade dentro do grupo
            total += Math.max(...grupo.map(item => item.value));
        } else {
            // Soma todos os valores multiplicados pela quantidade do grupo
            total += grupo.reduce((sum, item) => sum + item.value * item.qtd, 0);
        }
    }

    return total;
};
