import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api';
import { SearchClienteRequest } from '@/models/delivery/client.request';
import { SearchClienteResponse } from '@/models/delivery/client.response';
import { StoresResponse } from '@/models/delivery/stores.response';
import { StoresRequest } from '@/models/delivery/stores.request';

export const GetClient = createAsyncThunk('authDelivery/GetClient', async (request: SearchClienteRequest) => {
	const response = await api
		.post(`/busca-cliente`, request)
		.then((r): SearchClienteResponse => {
			api.defaults.headers['API-TOKEN'] = `${r.data.token}`;
			return r.data;
		})
		.catch(error => {
			return error.response.data;
		});

	return response;
});

export const GetStores = createAsyncThunk('authDelivery/GetStores', async (request: StoresRequest) => {
	const response = await api
		.post(`/busca-unidades`, request)
		.then((r): StoresResponse => {
			return r.data;
		})
		.catch(error => {
			return error.response.data;
		});
	return response;
});
